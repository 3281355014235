import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FormBottom = styled.div`
  margin-top: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ForgotPasswordMsg = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContainerTitle = styled.div`
  margin: 64px 0 40px 0;

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    color: ${(props) => props.theme.colors.black};
  }

  > div:nth-child(2) {
    > span {
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;
