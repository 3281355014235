import { useEffect, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { Role } from '../../types/UserRoles';

type AuthorizedProps = {
  role: Role | Role[];
};

const AuthorizedRoute: React.FC<PropsWithChildren<AuthorizedProps>> = ({ role, children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const hasRole = () => {
    const userGroups: string[] = user['cognito:groups'];

    if (typeof role === 'string') {
      return userGroups.some((group) => group.split('/')[1] === role);
    }

    if (typeof role === 'object') {
      return userGroups.some((group) => {
        for (const canRole of role) {
          if (group.split('/')[1] === canRole) return true;
        }

        return false;
      });
    }
  };

  useEffect(() => {
    if (user && !hasRole()) {
      navigate(-1);
    }
  });

  if (!user) return null;

  if (hasRole()) return <>{children}</>;

  return null;
};

export default AuthorizedRoute;
