import styled from 'styled-components';
import Button from '../Button/Button';
import breakpoints from '../../styles/breakpoints';

export const GeneralContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  background-color: ${(props) => props.theme.colors.lightlyGray};
`;

export const Aside = styled.aside`
  padding: 54px 24px 24px 24px;
  max-height: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.primary};
  display: none;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    display: flex;
    min-width: 220px;
  }

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    min-width: initial;
  }
`;

export const BrandLogo = styled.img`
  margin-bottom: 55px;
  height: 140;
`;

export const Nav = styled.nav``;

export const NavList = styled.ul`
  list-style: none;
`;

export const RometLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-left: 25px;
`;

export const PoweredByText = styled.span`
  font-size: 9px;
  opacity: 0.5;
  color: ${(props) => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)};
  margin-bottom: 8px;
`;

export const RometLogo = styled.img`
  width: 80px;
`;

export const Content = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 6;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    padding: 8px 40px;
  }
`;

export const HeaderMobile = styled.header`
  margin: -15px;
  padding: 15px;
  margin-bottom: 36px;
  background-color: ${(props) => props.theme.colors.primary};

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    display: none;
  }

  ${BrandLogo} {
    margin-bottom: 0;
    margin-left: 0;
  }

  ${Nav} {
    margin-top: 20px;

    a {
      padding: 15px;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  height: 48px;
  margin-bottom: 36px;

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    & > * {
      margin-left: 24px;
    }
  }

  @media screen and (max-width: ${(props) => breakpoints.md}) {
    display: none;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ProfileImg = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  object-fit: cover;

  margin-right: 8px;
`;

export const ProfileName = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
`;

export const DropdownUser = styled.div`
  padding: 40px 40px 32px 40px;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.08);
`;

export const UserImg = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 32px;
  object-fit: cover;
`;

export const UserName = styled.span`
  font-size: 24px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.black};
`;

export const UserEmail = styled.span`
  font-size: 14px;
  color: #999999;
  line-height: 24px;
  margin-bottom: 32px;
`;

export const ManageAccountBtn = styled(Button)`
  font-size: 14px;
  padding: 12px 24px;
  margin-bottom: 8px;
`;

export const LogoutButton = styled(Button)`
  position: relative;
  font-size: 14px;
  padding: 12px 24px;

  & svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 15px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    display: flex;
  }

  small {
    margin-top: 20px;
    display: block;

    @media screen and (min-width: ${(props) => breakpoints.md}) {
      margin-top: 10px;
    }
  }
`;

export const MainTitleWrapper = styled.div`
  display: block;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    display: flex;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.black};
`;

export const OptionalTitleContentWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
