import { BiChevronRight } from 'react-icons/bi';
import { VscSettingsGear } from 'react-icons/vsc';

import { createContext, useContext } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { Role } from '../../types/UserRoles';
import Button from '../Button/Button';
import Can from '../Can/Can';
import DropdownButton from '../DropdownButton/DropdownButton';
import NavItem from '../MainNavItem/MainNavItem';
import {
  Aside,
  BrandLogo,
  Container,
  Content,
  DropdownUser,
  Header,
  HeaderMobile,
  LogoutButton,
  Main,
  ManageAccountBtn,
  Nav,
  NavList,
  PoweredByText,
  ProfileName,
  RometLogo,
  RometLogoContainer,
  Row,
  UserEmail,
  UserName,
} from './style';

type MainLayoutContextValues = {};

type NavItem = {
  link: string;
  icon: JSX.Element;
  text: string;
  active: boolean;
  role?: Role[];
  disabled?: boolean;
};

const MainLayoutContext = createContext<MainLayoutContextValues>({});

export const useMainContextLayout = () => useContext(MainLayoutContext);

const MainLayout: React.FC = () => {
  const { pathname } = useLocation();
  const { logout, user, logo } = useAuth();

  const navItems: NavItem[] = [
    {
      link: '/',
      icon: <img src="/svgs/devices-solid.svg" />,
      text: 'Home',
      active: pathname === '/',
    },
    // {
    //   link: '/assets',
    //   icon: <img src="/svgs/devices-solid.svg" />,
    //   text: 'Assets',
    //   active: pathname === '/assets',
    // },
    // {
    //   link: '/customers',
    //   icon: <MdOutlineDomain />,
    //   text: 'Customers',
    //   active: pathname === '/customers',
    //   disabled: true,
    // },
    // {
    //   link: '/aggregators',
    //   icon: <MdOutlineGroupWork />,
    //   text: 'Aggregators',
    //   active: pathname === '/aggregators',
    //   disabled: true,
    // },
    // {
    //   link: '/programs',
    //   icon: <MdPlaylistAddCheck />,
    //   text: 'Programs',
    //   active: pathname === '/programs',
    //   disabled: true,
    // },
    // {
    //   link: '/operations',
    //   icon: <MdOutlineAccountBalance />,
    //   text: 'Operations',
    //   active: pathname === '/operations',
    //   disabled: true,
    // },
    // {
    //   link: '/billing',
    //   icon: <AiOutlineDollarCircle />,
    //   text: 'Billing',
    //   active: pathname === '/billing',
    //   disabled: true,
    // },
    // {
    //   link: '/contracts',
    //   icon: <MdOutlineTextSnippet />,
    //   text: 'Contracts',
    //   active: pathname === '/contracts',
    //   disabled: true,
    // },
    // {
    //   link: '/tasks and workflow',
    //   icon: <AiOutlineOrderedList />,
    //   text: 'Tasks and Workflow',
    //   active: pathname === '/tasks and workflow',
    //   disabled: true,
    // },
    // {
    //   link: '/reports',
    //   icon: <MdOutlinePictureAsPdf />,
    //   text: 'Reports',
    //   active: pathname === '/reports',
    //   disabled: true,
    // },
    // {
    //   link: '/users',
    //   icon: <img src="/svgs/users-solid.svg" />,
    //   text: 'Users',
    //   active: pathname === '/users',
    //   role: ['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin'],
    // },
    // {
    //   link: '/user-settings',
    //   icon: <IoMdSettings />,
    //   text: 'User Settings',
    //   active: pathname === '/user-settings',
    // },
    {
      link: '/administration/my-settings',
      icon: <VscSettingsGear />,
      text: 'Administration',
      active: pathname.includes('administration'),
      role: ['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin'],
    },
  ];

  return (
    <>
      <MainLayoutContext.Provider value={{}}>
        <Container>
          <Aside>
            <Link to="/">
              <BrandLogo
                style={{
                  marginLeft: logo ? 0 : 24,
                }}
                src={logo ? logo : '/images/romet-logo-white.png'}
              />
            </Link>
            <Nav>
              <NavList>
                {renderNavItems()}

                {/* <Can role={['admin', 'UtilityPowerUser', 'UtilityAdmin']}>
                  <NavItem
                    link="/threshold"
                    Icon={<img src="/svgs/threshold-solid.svg" />}
                    text="Thresholds"
                    active={pathname === '/threshold'}
                  />
                </Can> */}
              </NavList>
            </Nav>
            {logo && (
              <RometLogoContainer>
                <PoweredByText>Powered by</PoweredByText>
                <RometLogo src="/images/romet-logo-white.png" />
              </RometLogoContainer>
            )}
          </Aside>
          <Content>
            <HeaderMobile>
              <Row>
                <Link to="/">
                  <BrandLogo src={logo ? logo : '/images/romet-logo-white.png'} />
                </Link>
                <div style={{ display: 'flex' }}>
                  {/* <Button
                    type="link"
                    shape="circle"
                    variant="light"
                    to="/notifications"
                  >
                    <FaRegBell />
                  </Button> */}
                  <Can role={['CompanyAdmin', 'DeviceGroupAdmin']}>
                    <Button
                      type="link"
                      to="/company-settings"
                      shape="circle"
                      variant="light"
                    >
                      <VscSettingsGear />
                    </Button>
                  </Can>
                  <DropdownButton
                    shape="round"
                    variant="light"
                    zIndex={20}
                    renderContent={
                      <DropdownUser>
                        {/* <UserImg src="/images/profile-placeholder.png" /> */}
                        <UserName>User</UserName>
                        <UserEmail>{user.username}</UserEmail>
                        <ManageAccountBtn
                          onClick={() => logout}
                          type="link"
                          variant="light-primary"
                          to={`logout-and-redirect-to?redirectTo=/auth/forgot?email=${user.username}`}
                        >
                          Reset Password
                        </ManageAccountBtn>
                        {/* <ManageAccountBtn block variant="light-primary">
                      Manage your account
                    </ManageAccountBtn> */}
                        <LogoutButton
                          block
                          onClick={() => logout()}
                        >
                          Logout <BiChevronRight />
                        </LogoutButton>
                      </DropdownUser>
                    }
                  >
                    {/* <ProfileImg src="/images/profile-placeholder.png" /> */}
                    <ProfileName>User</ProfileName>
                  </DropdownButton>
                </div>
              </Row>
              <Nav>
                <NavList>{renderNavItems()}</NavList>
              </Nav>
            </HeaderMobile>
            <Header>
              {/* <SearchInput placeholder="Search for everything" /> */}
              {/* <Button
                type="link"
                shape="circle"
                variant="light"
                to="/notifications"
              >
                <FaRegBell />
              </Button> */}
              <Can role={['CompanyAdmin', 'DeviceGroupAdmin']}>
                <Button
                  type="link"
                  to="/company-settings"
                  shape="circle"
                  variant="light"
                >
                  <VscSettingsGear />
                </Button>
              </Can>
              <DropdownButton
                shape="round"
                variant="light"
                zIndex={20}
                renderContent={
                  <DropdownUser>
                    {/* <UserImg src="/images/profile-placeholder.png" /> */}
                    <UserName>User</UserName>
                    <UserEmail>{user.username}</UserEmail>
                    <ManageAccountBtn
                      onClick={() => logout}
                      type="link"
                      variant="light-primary"
                      to={`logout-and-redirect-to?redirectTo=/auth/forgot?email=${user.username}`}
                    >
                      Reset Password
                    </ManageAccountBtn>
                    {/* <ManageAccountBtn block variant="light-primary">
                      Manage your account
                    </ManageAccountBtn> */}
                    <LogoutButton
                      block
                      onClick={() => logout()}
                    >
                      Logout <BiChevronRight />
                    </LogoutButton>
                  </DropdownUser>
                }
              >
                {/* <ProfileImg src="/images/profile-placeholder.png" /> */}
                <ProfileName>User</ProfileName>
              </DropdownButton>
            </Header>
            <Main id="main">
              <Outlet />
            </Main>
          </Content>
        </Container>
      </MainLayoutContext.Provider>
    </>
  );

  function renderNavItems() {
    return navItems.map(({ link, icon, text, active, role, disabled }, index) => {
      if (role)
        return (
          <Can
            role={role}
            key={`${text} ${index}`}
          >
            <NavItem
              disabled={disabled}
              link={link}
              Icon={icon}
              text={text}
              active={active}
            />
          </Can>
        );

      return (
        <NavItem
          key={`${text} ${index}`}
          disabled={disabled}
          link={link}
          Icon={icon}
          text={text}
          active={active}
        />
      );
    });
  }
};

export default MainLayout;
