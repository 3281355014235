import { Outlet } from 'react-router-dom';

import Header from '../Header/Header';
import { NavTabItem } from '../NavTabs/NavTabs';

const AssetsLayout = () => {
  const tabs: NavTabItem[] = [
    {
      to: '/administration/my-settings',
      text: 'My Settings',
    },
  ];

  return (
    <>
      <Header
        title="Administration"
        navTabItems={tabs}
      />
      <Outlet />
    </>
  );
};

export default AssetsLayout;
