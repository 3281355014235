import { verifyTotpQuery } from './queries';
import { useEnableMfa } from './mutations';

export const useEnableTotp = () => {
  const { mutateAsync: enableMfa } = useEnableMfa();

  // Enables MFA for the user in 2 steps:
  //   1. Verifies the passed in auth code
  //   2. Enables TOTP

  const enableTotp = async (password: string, challengeAnswer: string) => {
    try {
      const { data } = await verifyTotpQuery({ challengeAnswer });

      if (!data.verifyTotp.success) {
        throw 'Invalid TOTP';
      }
    } catch (error) {
      throw 'Invalid TOTP';
    }

    try {
      const response = await enableMfa({ password });

      if (!response?.enableMfa.success) {
        throw 'Invalid password';
      }
    } catch (error) {
      throw 'Invalid password';
    }

    return { success: true };
  };

  return { enableTotp };
};
