import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import UnprotectedRouteLayout from './components/UnprotectedRouteLayout/UnprotectedRouteLayout';
import AuthProvider from './contexts/Auth';
import GlobalStyle from './styles/GlobalStyle';

import { QueryClient, QueryClientProvider } from 'react-query';
import { apolloClient } from './api-client/apollo-client';
import AssetsLayout from './components/AssetsLayout/AssetsLayout';
import AuthorizedRoute from './components/AuthorizedRoute/AuthorizedRoute';
import MainLayout from './components/MainLayout/MainLayout';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute/UnprotectedRoute';
import Theme from './contexts/Theme';
import Page404 from './screens/404/404';
import CheckEmail from './screens/CheckEmail/CheckEmail';
import CreateNewPassword from './screens/CreateNewPassword/CreateNewPassword';
import Login from './screens/Login/Login';
import LogoutAndRedirectTo from './screens/LogoutAndRedirectTo/LogoutAndRedirectTo';
import CreatePassword from './screens/MFA/CreatePassword/CreatePassword';
import EnableMfa from './screens/MFA/EnableMfa/EnableMfa';
import LoginMFA from './screens/MFA/LoginMFA/LoginMFA';
import VerifyEmail from './screens/MFA/VerifyEmail/VerifyEmail';
import PasswordResetMsg from './screens/PasswordResetMsg/PasswordResetMsg';
import Register from './screens/Register/Register';
import ResetPassword from './screens/ResetPassword/ResetPassword';
import UserSettings from './screens/UserSettings/UserSettings';
import { Home } from './screens/Home';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <Theme>
          <AuthProvider>
            <GlobalStyle />
            <BrowserRouter>
              <Routes>
                <Route
                  path="logout-and-redirect-to"
                  element={<LogoutAndRedirectTo />}
                />
                <Route
                  path="auth"
                  element={
                    <UnprotectedRoute>
                      <UnprotectedRouteLayout />
                    </UnprotectedRoute>
                  }
                >
                  <Route
                    path="login"
                    element={<Login />}
                  />
                  <Route
                    path="forgot"
                    element={<ResetPassword />}
                  />
                  <Route
                    path="check-email"
                    element={<CheckEmail />}
                  />
                  <Route
                    path="reset-password"
                    element={<CreateNewPassword />}
                  />
                  <Route
                    path="registration"
                    element={<Register />}
                  />
                  <Route
                    path="reset-password-msg"
                    element={<PasswordResetMsg />}
                  />
                </Route>

                <Route
                  path="mfa"
                  element={
                    <ProtectedRoute>
                      <UnprotectedRouteLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="create-password"
                    element={<CreatePassword />}
                  />
                  <Route
                    path="enable"
                    element={<EnableMfa />}
                  />
                  <Route
                    path="login"
                    element={<LoginMFA />}
                  />
                </Route>
                <Route
                  path="verify-email"
                  element={<VerifyEmail />}
                />
                <Route
                  element={
                    <ProtectedRoute>
                      <MainLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="/"
                    // element={<Navigate to="/auth/login" replace />}
                    element={<Home />}
                  />
                  <Route
                    path="administration"
                    element={
                      <AuthorizedRoute
                        role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}
                      >
                        <AssetsLayout />
                      </AuthorizedRoute>
                    }
                  >
                    <Route
                      path="my-settings"
                      element={<UserSettings />}
                    />
                  </Route>
                </Route>
                <Route
                  path="*"
                  element={<Page404 />}
                />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </Theme>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

export default App;
