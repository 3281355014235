import React from 'react';

import { Container, Title, List, ListItem } from './style';

const requirements = [
  {
    text: '8 characters minimun',
    pattern: /.{8,}/,
  },
  {
    text: 'At least 1 uppercase letter',
    pattern: /[A-Z]/,
  },
  {
    text: 'At least 1 lowercase letter',
    pattern: /[a-z]/,
  },
  {
    text: 'At least 1 numeric character',
    pattern: /[0-9]/,
  },
  {
    text: 'At least 1 special character',
    pattern: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  },
];

type Props = {
  password: string;
};

const PasswordRequirements: React.FC<Props> = ({ password }) => {
  const testPassword = (password: string, pattern: RegExp) => {
    return pattern.test(password);
  };
  return (
    <Container>
      <Title>Password requirement:</Title>

      <List>
        {requirements.map(({ text, pattern }, index) => {
          const passed = testPassword(password, pattern);
          return (
            <ListItem
              key={index}
              passed={passed}
            >
              {text}
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default PasswordRequirements;
