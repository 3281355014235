import Button from '../../components/Button/Button';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';

import { Title, Subtitle, FormBottom, BackToLoginMessage } from './style';
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import errorMessages from '../../config/errorMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForgotPassword } from '../../hooks/mutations';

type Inputs = {
  username: string;
};

const schema = yup.object().shape({
  username: yup.string().email(errorMessages.email).required(errorMessages.required),
});

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { mutateAsync: forgotPasswordMutation, isLoading } = useForgotPassword();
  const { setSideImagePath, setBackgroundImagePath } = useUnprotectedLayout();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email') || undefined;

  useEffect(() => {
    setSideImagePath!('/images/password-lock.jpg');
    setBackgroundImagePath!('/images/earth.jpg');
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      const { username } = formData;
      await forgotPasswordMutation({ username });
      navigate(`/auth/check-email?email=${username}`);
    } catch (e) {
      setError('username', {
        type: 'custom',
        message: 'Email not found',
      });
    }
  };

  return (
    <>
      <Title>Reset Password</Title>
      <Subtitle>Enter your email and we'll send the instructions</Subtitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormControl
            register={register}
            label="Email address"
            id="emailAddress"
            type="text"
            name="username"
            error={errors.username?.message}
            value={email}
          />
        </FormGroup>
        <FormGroup>
          <Button
            block
            loading={isLoading}
            type="submit"
          >
            Reset Password
          </Button>
        </FormGroup>
      </form>
      <FormBottom onClick={() => navigate('/auth/login')}>
        <AiOutlineLeft />
        <BackToLoginMessage>Back to log in</BackToLoginMessage>
      </FormBottom>
    </>
  );
};

export default ResetPassword;
