import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FormBottom = styled.div`
  margin-top: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ForgotPasswordMsg = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;
