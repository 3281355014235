import styled, { css } from 'styled-components';
import { BadgeProps, BadgeVariants } from './Badge';

export const getBackgroundColor = (variant: BadgeVariants) => {
  switch (variant) {
    // case "danger":
    //     return css`
    //   ${(props) => props.theme.colors.dangerBg}
    // `;
    case 'success':
      return css`
        ${(props) => props.theme.colors.successBg}
      `;
    // case "in-progress":
    //     return css`
    //   ${(props) => props.theme.colors.successBg}
    // `;
  }
};

export const getColor = (variant?: BadgeVariants) => {
  switch (variant) {
    // case "danger":
    //   return css`
    //       ${(props) => props.theme.colors.danger}
    //     `;
    case 'success':
      return css`
        ${(props) => props.theme.colors.success}
      `;
    // case "in-progress":
    //   return css`
    //       ${(props) => props.theme.colors.success}
    //     `;
  }
};

export const Container = styled.span<BadgeProps>`
  border-radius: 58px;
  font-size: 12px;
  padding: 2px 8px;
  color: ${(props) => getColor(props.variant)};
  background-color: ${(props) => getBackgroundColor(props.variant)};
  font-weight: bold;
  display: inline;
`;
