import { forwardRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Container, ErrorMessage, Input, Label, SubLabel } from './style';

type CheckboxProps = {
  label: string;
  name?: string;
  id?: string;
  subLabel?: string;
  register?: UseFormRegister<any>;
  error?: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { label, name, id, subLabel, register, error, value, onChange, onClick, checked, disabled },
    ref
  ) => {
    return (
      <>
        <Container>
          <Input
            disabled={disabled}
            type="checkbox"
            defaultValue={value}
            {...register!(name || '', {
              onChange,
            })}
            id={id}
            defaultChecked={checked}
            onClick={onClick}
          />
          <Label htmlFor={id}>
            {label}
            {subLabel && (
              <>
                <br />
                <SubLabel>{subLabel}</SubLabel>
              </>
            )}
            {error && (
              <>
                <br />
                <ErrorMessage>{error}</ErrorMessage>
              </>
            )}
          </Label>
        </Container>
      </>
    );
  }
);

export default Checkbox;
