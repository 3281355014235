// Function to query devices from the API
// Use React Query
// https://react-query.tanstack.com/

import { QueryObserverOptions, QueryOptions, useQuery } from 'react-query';
import {
  GetAssociatedSecretCodeQueryVariables,
  GetAssociatedSecretCodeQuery,
  VerifyTotpQuery,
  VerifyTotpQueryVariables,
  GetCurrentUserQueryVariables,
  GetCurrentUserQuery,
} from '../API';
import { useAuth } from '../contexts/Auth';
import { getAssociatedSecretCode, verifyTotp, getCurrentUserQuery } from '../graphql/queries';
import { apiClient } from '../api-client';
import { DocumentNode, OperationVariables } from '@apollo/client';

export const defaultComponent = 'CCB';

type BaseQueryParams<TQuery, TVariables extends OperationVariables = {}> = {
  query: DocumentNode;
  variables: TVariables;
  key: string;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
  additionalKeys?: any[];
};

type CustomQueryParams<TQuery, TVariables extends OperationVariables = {}> = {
  variables?: TVariables;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
};

function useBaseQuery<TQuery, TVariables extends OperationVariables = {}>({
  query,
  variables,
  key,
  additionalOptions,
  additionalKeys,
}: BaseQueryParams<TQuery, TVariables>) {
  const { logout } = useAuth();
  return useQuery<TQuery>({
    queryKey: [key, variables, ...(additionalKeys ?? [])],
    queryFn: fetchFunc,
    retry: false,
    staleTime: 1000 * 60,
    ...additionalOptions,
  });

  async function fetchFunc() {
    try {
      const { data } = await apiClient.query<TVariables, TQuery>(query, {
        ...variables,
      });

      return data;
    } catch (e: unknown) {
      throw apiClient.errorHandle(e, logout);
    }
  }
}

export const useGetSecretCode = createCustomQuery<GetAssociatedSecretCodeQuery>({
  query: getAssociatedSecretCode,
  key: 'getAssociatedSecretCode',
});

export const getSecretCode = async (variables: GetAssociatedSecretCodeQueryVariables) => {
  return apiClient.query<GetAssociatedSecretCodeQueryVariables, GetAssociatedSecretCodeQuery>(
    getAssociatedSecretCode,
    variables
  );
};

export const verifyTotpQuery = (variables: VerifyTotpQueryVariables) => {
  return apiClient.query<VerifyTotpQueryVariables, VerifyTotpQuery>(verifyTotp, variables);
};

export const getCurrentUser = () => {
  return apiClient.query<GetCurrentUserQueryVariables, GetCurrentUserQuery>(
    getCurrentUserQuery,
    {}
  );
};

export const useGetCurrentUser = createCustomQuery<GetCurrentUserQuery>({
  query: getCurrentUserQuery,
  key: 'getCurrentUserQuery',
});

type CreateCustomQueryParams = {
  query: DocumentNode;
  key: string;
};

function createCustomQuery<TQuery, TVariables extends OperationVariables = {}>({
  query,
  key,
}: CreateCustomQueryParams) {
  return ({ variables, additionalOptions }: CustomQueryParams<TQuery, TVariables> = {}) =>
    useBaseQuery<TQuery, TVariables>({
      query,
      variables: { ...variables! },
      key,
      additionalOptions,
    });
}
