import React from 'react';

import { Spin } from './style';

type SpinnerProps = {
  variant?: 'primary';
};

const Spinner: React.FC<SpinnerProps> = ({ variant }) => {
  return (
    <Spin
      data-testid="spinner"
      variant={variant}
    />
  );
};

export default Spinner;
