import styled from 'styled-components';

export const Title = styled.h1``;

export const Subtitle = styled.h2``;

export const FormBottom = styled.div`
  margin-top: 28px;
  cursor: pointer;
  display: inline-block;

  & * {
    color: ${(props) => props.theme.colors.primary};
  }

  & svg {
    font-size: 10px;
    margin-right: 14px;
  }

  &:hover {
    & span {
      text-decoration: underline;
    }
  }
`;

export const BackToLoginMessage = styled.span`
  font-size: 14px;
`;
