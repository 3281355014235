import styled from 'styled-components';

type ListItemProps = {
  passed: boolean;
};

export const Container = styled.div`
  padding: 16px;
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.colors.gray} 1px solid;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px 0;
  width: max-content;
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
`;

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li<ListItemProps>`
  font-size: 12px;
  margin-bottom: 2px;
  color: ${(props) => (props.passed ? props.theme.colors.black : props.theme.colors.danger)};

  &::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${(props) =>
      props.passed ? props.theme.colors.success : props.theme.colors.danger};
    border-radius: 50%;
    margin-right: 8px;
  }
`;
