import React from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { useTheme } from 'styled-components';
import Badge, { BadgeItem } from '../Badge/Badge';
import ButtonsGroup, { ButtonItem } from '../ButtonsGroup/ButtonsGroup';
import Row from '../Layout/Row/Row';
import NavTabs, { NavTabItem } from '../NavTabs/NavTabs';
import Pill, { PillItem } from '../Pill/Pill';
import Typography from '../Typography/Typography';

type Props = {
  navTabItems?: NavTabItem[];
  title: string;
  subtitle?: string;
  buttonGroupItems?: ButtonItem[];
  icons?: PillItem | PillItem[];
  badge?: BadgeItem;
};

const Header: React.FC<Props> = ({
  navTabItems,
  title,
  subtitle,
  buttonGroupItems,
  icons,
  badge,
}) => {
  const { colors } = useTheme();

  const renderPills = () => {
    if (!icons) return;

    if (Array.isArray(icons)) {
      return (
        <>
          <Row
            alignItems={'center'}
            spacing={15}
          >
            {icons.map(({ color, icon }, index) => (
              <>
                <div>
                  <Pill
                    color={color}
                    icon={icon}
                  />
                </div>

                <div>
                  {index + 1 !== icons.length && (
                    <MdArrowForwardIos
                      style={{
                        fontSize: 13,
                      }}
                    />
                  )}
                </div>
              </>
            ))}
          </Row>
        </>
      );
    } else {
      const { color, icon } = icons;
      return (
        <Pill
          color={color}
          icon={icon}
        />
      );
    }
  };

  return (
    <>
      <Row
        spacing={15}
        alignItems="center"
        style={{
          marginBottom: 40,
        }}
      >
        {icons && <div>{renderPills()}</div>}
        <div>
          <Typography
            as={'h1'}
            fontSize={24}
            fontWeight={'bold'}
            lineHeight="40px"
            color={colors.black}
            whiteSpace="nowrap"
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              as="h3"
              fontSize={12}
              fontWeight={400}
              lineHeight="20px"
              color={colors.black}
              whiteSpace="nowrap"
            >
              {subtitle}
            </Typography>
          )}
        </div>
        <div>{buttonGroupItems && <ButtonsGroup buttons={buttonGroupItems} />}</div>
        <div style={{ display: 'flex', height: '100%' }}>
          {badge && (
            <Badge
              style={{ alignSelf: 'flex-end' }}
              variant={badge.variant}
            >
              {badge.text}
            </Badge>
          )}
        </div>
      </Row>
      {navTabItems && <NavTabs items={navTabItems} />}
    </>
  );

  // return <>
  //     <TitleContainer>
  //         <MainTitleWrapper>
  //             {icons && renderPills()}
  //             <TitleWrapper>
  //                 {subtitle && <Subtitle>
  //                     {subtitle}
  //                 </Subtitle>}
  //                 <Title
  //                     style={{
  //                         marginRight: 40,
  //                     }}
  //                 >
  //                     {title}
  //                 </Title>
  //             </TitleWrapper>
  //             {buttonGroupItems && <ButtonsGroup
  //                 buttons={buttonGroupItems}
  //             />}
  //         </MainTitleWrapper>
  //     </TitleContainer>
  //     {navTabItems && <NavTabs items={navTabItems} />}
  // </>;
};

export default Header;
