export default class StringUtils {
  static toCamelCase(text: string): string {
    const toLowerCase = text.toLowerCase();
    const camelCase = toLowerCase.replaceAll(/ +([a-z])/g, (match, letter: string) =>
      letter.toUpperCase()
    );

    return camelCase;
  }

  static firstChartUppercase = (text: string): string =>
    text.replace(/^[a-z]/, (match) => match.toUpperCase());
}
