import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type TabProps = {
  $active?: boolean;
};

export const Container = styled.div`
  display: flex;
  margin-bottom: 40px;
  overflow-x: auto;
`;

const getTabBorderColor = (active?: boolean) => {
  return css`
    ${(props) => (active ? props.theme.colors.primary : props.theme.colors.gray)};
  `;
};

export const Tab = styled(Link)<TabProps>`
  padding: 16px 24px;
  border: none;
  border-bottom: 2px solid ${(props) => getTabBorderColor(props.$active)};
  font-weight: ${(props) => (props.$active ? 'bold' : 'normal')};
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  transition: 100ms all;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }
`;

export const Rest = styled.div`
  height: 100%;
  flex-grow: 1;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
`;
