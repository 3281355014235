import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../components/Button/Button';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordRequirements from '../../components/PasswordRequirements/PasswordRequirements';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';
import errorMessages from '../../config/errorMessages';
import { Subtitle, Title } from './style';
import { useConfirmForgotPass } from '../../hooks/mutations';

type Inputs = {
  password: string;
  confirmNewPassword: string;
  username: string;
};

const schema = yup.object().shape({
  username: yup.string().required(errorMessages.required).email(errorMessages.email),
  password: yup
    .string()
    .required(errorMessages.required)
    .min(8, errorMessages.minCharacters(8))
    .matches(/[a-z]/g, 'Password must have lowercase characters')
    .matches(/[!@#$%^&*(),.?":{}|<>]/g, 'Password must have symbol characters')
    .matches(/[A-Z]/g, 'Password must have uppercase characters')
    .matches(/[0-9]/g, 'Password must have numeric characters'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match")
    .required(errorMessages.required),
});

const CreateNewPassword: React.FC = () => {
  const navigate = useNavigate();
  const { mutateAsync: confirmForgotPasswordMutations, isLoading } = useConfirmForgotPass();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email') || undefined;
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState(searchParams.get('code'));
  const [showRequirements, setShowRequirements] = useState(false);

  const { setBackgroundImagePath, setSideImagePath } = useUnprotectedLayout();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setBackgroundImagePath!('/images/earth.jpg');

    if (!code) {
      navigate(-1);
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async ({ password, username }) => {
    try {
      await confirmForgotPasswordMutations({
        password,
        code: code!,
        username,
      });

      setSuccess(true);
    } catch (e) {
      setError('username', {
        type: 'custom',
        message: 'Invalid code or email provided',
      });
    }
  };
  return (
    <>
      {success ? (
        <>
          <Title>Password Changed Successfully</Title>
          <Subtitle>It is now possible to login using your new credentials.</Subtitle>

          <FormGroup>
            <Button
              block
              onClick={() => navigate('/auth/login')}
            >
              Log in
            </Button>
          </FormGroup>
        </>
      ) : (
        <>
          <Title>Create New Password</Title>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormControl
                label="Email"
                id="username"
                name="username"
                type={'text'}
                register={register}
                error={errors.username?.message}
                value={email}
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                label="New Password"
                id="newPassword"
                type="password"
                name="password"
                register={register}
                error={errors.password?.message}
                onFocus={() => setShowRequirements(true)}
                onBlur={() => setShowRequirements(false)}
                dropdown={
                  showRequirements ? <PasswordRequirements password={watch('password')} /> : null
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                label="Confirm New Password"
                id="confirmNewPassword"
                type="password"
                name="confirmNewPassword"
                register={register}
                error={errors.confirmNewPassword?.message}
              />
            </FormGroup>
            <FormGroup>
              <Button
                loading={isLoading}
                type="submit"
              >
                Reset Password
              </Button>
            </FormGroup>
          </form>
        </>
      )}
    </>
  );
};

export default CreateNewPassword;
