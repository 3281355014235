import { PropsWithChildren } from 'react';
import { Container } from './style';

export type BadgeVariants =
  // | "danger"
  'success';
// | "in-progress";

export type BadgeItem = {
  variant: BadgeVariants;
  text: string;
};

export type BadgeProps = {
  variant: BadgeVariants;
  style?: React.CSSProperties;
};

const Badge: React.FC<PropsWithChildren<BadgeProps>> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default Badge;
