import styled from 'styled-components';

type RowReverse = boolean;

type InputProps = {
  error: boolean;
  rowReverse?: RowReverse;
  width?: number;
};

export const InputWrapper = styled.div<InputProps>`
  width: 100%;
  height: ${(props) => props.theme.inputHeight};
  position: relative;
  border-radius: ${(props) => props.theme.borderRadius};

  border: 1px solid
    ${(props) => (props.error ? props.theme.colors.danger : props.theme.colors.gray)};
  display: flex;
  flex-direction: ${(props) => props.rowReverse && 'row-reverse'};
`;

export const Label = styled.label<InputProps>`
  position: absolute;
  left: ${(props) => (props.rowReverse ? '40px' : '8px')};
  top: 50%;
  transform: translate(0, -50%);
  background-color: ${(props) => props.theme.colors.white};
  padding: '0 5px';
  transition: all 100ms ease-in-out;
  color: ${(props) => props.theme.colors.gray};
  pointer-events: none;
  font-size: 1rem;
  width: ${(props) => props.width && props.width}%;
  text-align: center;
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  border: none;
  flex-grow: 1;
  height: 100%;
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius};
  padding-left: 12px;
  transition: all 100ms ease-in-out;
  background-color: ${(props) => props.theme.colors.white};

  &::placeholder {
    color: transparent;
  }

  &:disabled + label {
    background-color: transparent;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:is(:not(:placeholder-shown), :focus) + label {
    font-size: 12px;
    top: 0;
    color: ${(props) => (props.error ? props.theme.colors.danger : props.theme.colors.primaryText)};
  }

  &:is(:not(:placeholder-shown), :focus) {
    border-color: ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.primaryText};
  }
`;

export const InputIcon = styled.div`
  cursor: pointer;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 20px;
  }
`;

export const InputContentContainer = styled.div``;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.danger};
  font-size: 12px;
  display: block;
  line-height: 20px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const DropdownContainer = styled.div`
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
`;

export const SupportText = styled.span`
  display: block;

  color: ${(props) => props.theme.colors.gray};
  font-size: 12px;
  margin-left: 16px;
  line-height: 20px;
`;
