import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { ButtonShapes, ButtonVariants } from '../Button/Button';
import { DropIcon, DropdownBtn, DropdownContent, DropdownWrapper } from './style';

type DropdownPosition = 'top' | 'bottom';

type DropdownButtonProps = {
  variant?: ButtonVariants;
  shape?: ButtonShapes;
  renderContent: React.ReactNode;
  dropIcon?: boolean;
  zIndex?: number;
  dropdownPosition?: DropdownPosition;
};

const DropdownButton: React.FC<PropsWithChildren<DropdownButtonProps>> = ({
  children,
  variant,
  renderContent,
  shape,
  dropIcon = true,
  zIndex,
  dropdownPosition,
}) => {
  const dropdownContentRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClickDropdownButton = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      if (dropdownContentRef.current && !dropdownContentRef.current.contains(e.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.body.addEventListener('click', callback);

    return () => {
      document.body.removeEventListener('click', callback);
    };
  }, []);

  return (
    <DropdownWrapper>
      <DropdownBtn
        onClick={handleClickDropdownButton}
        shape={shape}
        variant={variant}
      >
        {children}
        {dropIcon === true && <DropIcon />}
      </DropdownBtn>
      {dropdownOpen && (
        <DropdownContent
          dropdownPosition={dropdownPosition}
          ref={dropdownContentRef}
          zIndex={zIndex || 10}
        >
          {renderContent}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
};

export default DropdownButton;
