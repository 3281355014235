import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/Auth';
import { useSearchParams, useNavigate } from 'react-router-dom';

const LogoutAndRedirectTo: React.FC = () => {
  const { logout, signed } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!signed) {
      navigate(-1);
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    if (!signed) {
      const pathToRedirect = searchParams.get('redirectTo');

      if (!pathToRedirect) {
        navigate(-1);
      }

      navigate(pathToRedirect!, {
        replace: true,
      });
    }
  }, [signed]);

  return null;
};

export default LogoutAndRedirectTo;
