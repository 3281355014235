import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';

import { Title, Subtitle } from './style';

const CheckEmail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get('email'));
  const params = useSearchParams();
  const { setBackgroundImagePath, setSideImagePath } = useUnprotectedLayout();

  useEffect(() => {
    setSideImagePath!('/images/password-lock.jpg');
    setBackgroundImagePath!('/images/earth.jpg');

    if (!email) {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <Title>Check your email</Title>
      <Subtitle>
        We've sent an email to {email || 'EMAIL'} with a link to get back into your account.
      </Subtitle>
      <FormGroup>
        <Button
          block
          onClick={() => navigate('/auth/login')}
        >
          Log in
        </Button>
      </FormGroup>
    </>
  );
};

export default CheckEmail;
