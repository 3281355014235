import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  isDark: false,
  colors: {
    primary: '#0C2340',
    primaryText: '#0C2340',
    gray: '#D7D7D8',
    lightGray: '#E7E9EC',
    lightlyGray: '#f5f5f5',
    white: '#FFFFFF',
    lightBlack: '#323232',
    black: '#000000',
    neutral: '#F7F7F7',
    danger: '#DD1B1B',
    success: '#05CD99',
    info: '#6488EA',
    successBg: '#05CD991A',
  },
  breakpoints: {
    sm: '0px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  borderRadius: '8px',
  inputHeight: '50px',
  inputHorizontalPadding: '12px',
};

export default theme;
