import React, { PropsWithChildren } from 'react';

type Props = {
  as?: React.ElementType;
} & React.CSSProperties;

const Typography: React.FC<PropsWithChildren<Props>> = ({
  children,
  as = 'span',
  display = 'block',
  fontSize = 16,
  lineHeight = '32px',
  fontWeight = 400,
  ...props
}) => {
  const Component = as;
  return (
    <Component style={{ display, fontSize, fontWeight, lineHeight, ...props }}>
      {children}
    </Component>
  );
};

export default Typography;
