import { apolloClient } from './apollo-client';
import { ApolloError, type DocumentNode, type OperationVariables } from '@apollo/client';

const errorParse = (error: unknown): Error => {
  if (error instanceof Error) {
    try {
      return JSON.parse(error.message);
    } catch (e) {
      return { message: error.message, name: error.name };
    }
  } else if (typeof error === 'string') {
    return new Error(error);
  }
  return new Error('Error unknown type');
};

export const errorParseMessage = (error: unknown): string => {
  return errorParse(error).message;
};

export class apiClient {
  static async query<Variables extends OperationVariables, ReturnType>(
    query: DocumentNode,
    variables: Variables
  ) {
    return apolloClient.query<ReturnType, Variables>({
      query: query,
      variables: variables,
    });
  }

  static async mutate<Variables extends OperationVariables, ReturnType>(
    mutation: DocumentNode,
    variables: Variables
  ) {
    return apolloClient.mutate<ReturnType, Variables>({
      mutation: mutation,
      variables: { ...variables },
    });
  }

  static errorHandle(e: unknown, logout: () => void) {
    if (e instanceof ApolloError) {
      const networkError = e.networkError!;

      if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) logout();
    }

    throw errorParseMessage(e);
  }
}
