import styled from 'styled-components';

export const FormR = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  & > *:not(:last-child) {
    margin-right: 12px;
  }
`;
