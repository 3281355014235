import React from 'react';
import { useLocation } from 'react-router-dom';

import { Container, Rest, Tab } from './style';
import { Role } from '../../types/UserRoles';
import { useAuth } from '../../contexts/Auth';

export type NavTabItem = {
  to: string;
  text: string;
  role?: Role | Role[];
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  active?: boolean;
};

type ItemSubScreenProps = {
  items: NavTabItem[];
};

const NavTabs: React.FC<ItemSubScreenProps> = ({ items }) => {
  const { pathname } = useLocation();
  const { refactoredUser } = useAuth();

  const isOnURI = (uri: string) => {
    const pattern = new RegExp(`^${uri}.*`, 'g');

    return pattern.test(pathname);
  };

  return (
    <Container>
      {items
        .filter(({ role }) => {
          if (role && role.length > 0) {
            return refactoredUser?.hasRole(role);
          }

          return true;
        })
        .map(({ text, to, onClick, active }, index) => (
          <Tab
            to={to}
            onClick={onClick}
            $active={typeof active === 'boolean' ? active : isOnURI(to)}
            key={index}
          >
            {text}
          </Tab>
        ))}

      <Rest />
    </Container>
  );
};

export default NavTabs;
