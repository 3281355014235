import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Background,
  Content,
  FormContainer,
  LogoImages,
  ModalContainer,
  RometLogo,
  RometLogoContainer,
} from './style';

type ProviderValues = {
  setBackgroundImagePath?: React.Dispatch<React.SetStateAction<string>>;
  setSideImagePath?: React.Dispatch<React.SetStateAction<string>>;
};

const UnprotectedLayoutContext = createContext<ProviderValues>({});

const UnprotectedRouteLayout: React.FC = () => {
  const [backgroundImagePath, setBackgroundImagePath] = useState<string>('/images/earth.jpg');

  const [sideImagePath, setSideImagePath] = useState<string>(
    '/images/enbridge-logo-in-building.jpg'
  );

  return (
    <UnprotectedLayoutContext.Provider value={{ setBackgroundImagePath, setSideImagePath }}>
      <Background src={backgroundImagePath}>
        <ModalContainer>
          <Content>
            <LogoImages>
              <RometLogoContainer>
                <RometLogo src="/images/romet-logo.jpg" />
              </RometLogoContainer>
            </LogoImages>
            <FormContainer>
              <Outlet />
            </FormContainer>
          </Content>
        </ModalContainer>
      </Background>
    </UnprotectedLayoutContext.Provider>
  );
};

export const useUnprotectedLayout = () => useContext<ProviderValues>(UnprotectedLayoutContext);

export default UnprotectedRouteLayout;
