import { gql } from '@apollo/client';

export const enableMfa = gql`
  mutation EnableMfa($password: String!) {
    enableMfa(password: $password) {
      success
      message
      __typename
    }
  }
`;

export const disableMfa = gql`
  mutation DisableMfa($challengeAnswer: String!) {
    disableMfa(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;

export const forgotPassword = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username) {
      success
      __typename
    }
  }
`;

export const confirmForgotPassword = gql`
  mutation ConfirmForgotPassword($code: String!, $username: String!, $password: String!) {
    confirmForgotPassword(code: $code, username: $username, password: $password) {
      success
      __typename
    }
  }
`;
