import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

import { Container, Message404 } from './style';

const Page404: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Message404>404 NOT FOUND</Message404>
      <Button onClick={() => navigate('/')}>Home Page</Button>
    </Container>
  );
};

export default Page404;
