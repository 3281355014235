import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import awsExports from '../aws-exports';
import Auth from '../services/Auth';

const URL_AUTH = awsExports.aws_appsync_graphqlEndpoint;
const API_KEY = awsExports.aws_appsync_apiKey;

const authLink = setContext((_, prevContext) => {
  const accessToken = Auth.getToken();

  const context = {
    ...prevContext,
    headers: { ...prevContext.headers },
  };

  if (!accessToken) {
    context.headers['X-API-KEY'] = API_KEY;
  } else {
    context.headers['authorization'] = accessToken;
  }

  return context;
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

const apolloClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: URL_AUTH,
    })
  ),
  cache: new InMemoryCache(),
  defaultOptions,
});

export { apolloClient };
