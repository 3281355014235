import styled from 'styled-components';
import Button from '../../../components/Button/Button';

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const GenerateQrCodeButton = styled(Button)`
  padding: 6px 8px;
  font-size: 12px;
  line-height: 20px;
  height: 32px;
  min-height: 32px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
