import React, { useEffect, useState } from 'react';
import { MainTitleWrapper, Title, TitleContainer } from '../../components/MainLayout/style';
import Button from '../../components/Button/Button';
import { Container, Table } from './style';
import { RiPencilLine } from 'react-icons/ri';
import { useAuth } from '../../contexts/Auth';
import EnableMfaModal from '../../components/Modal/EnableMfaModal/EnableMfaModal';
import DisableMfaModal from '../../components/Modal/DisableMfaModal/DisableMfaModal';

const UserSettings: React.FC = () => {
  const { mfaEnabled, setMfaEnabled } = useAuth();

  const [enableMfaModalOpen, setEnableMfaModalOpen] = useState(false);
  const [disableMfaModalOpen, setDisableMfaModalOpen] = useState(false);

  const openMfaModal = () => {
    mfaEnabled ? setDisableMfaModalOpen(true) : setEnableMfaModalOpen(true);
  };

  return (
    <>
      <EnableMfaModal
        open={enableMfaModalOpen}
        closeModalFunc={() => setEnableMfaModalOpen(false)}
      />
      <DisableMfaModal
        open={disableMfaModalOpen}
        closeModalFunc={() => setDisableMfaModalOpen(false)}
      />

      {/* <TitleContainer>
      <MainTitleWrapper>
        <Title>User Settings</Title>
      </MainTitleWrapper>
      </TitleContainer> */}

      <Container>
        <Table>
          <tr>
            <td>Multi-factor authentication</td>
            <td>
              <strong>{mfaEnabled ? 'Enabled' : 'Disabled'}</strong>
            </td>
            <td>
              <Button
                onClick={() => openMfaModal()}
                variant="light"
                shape="round"
                loading={enableMfaModalOpen || disableMfaModalOpen}
              >
                <RiPencilLine />
              </Button>
            </td>
          </tr>
        </Table>
      </Container>
    </>
  );
};

export default UserSettings;
