import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

type ImageProps = {
  src: string | null;
};

export const Background = styled.div<ImageProps>`
  width: 100%;
  min-height: 100vh;
  ${(props) =>
    props.src
      ? `background-image: url(${props.src});`
      : `background-image: url('/images/earth.jpg')};`}
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: ${breakpoints.sm}) {
    background: none;
  }
`;

export const ModalContainer = styled.div`
  background-color: white;
  min-width: 40%;
  min-height: 600px;
  max-width: 1024px;
  z-index: 5;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.lg}) {
    width: 55%;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 90%;
    height: 90%;
    max-width: initial;
    max-height: initial;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 80px;

  & h1 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  & h2 {
    font-size: 12px;
    margin-bottom: 40px;
    color: ${(props) => props.theme.colors.gray};
  }

  @media (max-width: ${breakpoints.lg}) {
    padding: 60px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 40px;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    padding: 30px;
  }
`;

export const SideImage = styled.div<ImageProps>`
  width: 400px;
  ${(props) =>
    props.src
      ? `background-image: url(${props.src});`
      : `background-image: url('/images/enbridge-logo-in-building.jpg')};`}
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${breakpoints.sm}) {
    display: none;
    background: none;
  }
`;

export const LogoImages = styled.div`
  display: flex;
  height: 28px;
  margin-bottom: 29px;
`;

export const EnbridgeLogo = styled.img``;

export const RometLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const PoweredByText = styled.span`
  font-size: 9px;
  color: #0c2340;
`;

export const RometLogo = styled.img`
  width: 134px;
`;

export const FormContainer = styled.div``;
