import React, { PropsWithChildren } from 'react';

import { Container } from './style';

export type ColNums = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ContainerProps = {
  xs?: ColNums;
  sm?: ColNums;
  md?: ColNums;
  lg?: ColNums;
  xl?: ColNums;
  xxl?: ColNums;
  style?: React.CSSProperties;
};

const Col: React.FC<PropsWithChildren<ContainerProps>> = ({
  children,
  xs = 0,
  style,
  ...props
}) => {
  return (
    <Container
      style={style}
      {...props}
    >
      {children}
    </Container>
  );
};

export default Col;
