import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { signed, loading, refactoredUser } = useAuth();
  const location = useLocation();

  const isMFAEnabled = refactoredUser?.isMFAEnabled();

  if (loading) return null;

  if (!signed)
    return (
      <Navigate
        to={'/auth/login'}
        replace
      />
    );

  if (!isMFAEnabled && location.pathname !== '/mfa/enable') {
    return (
      <Navigate
        to={'/mfa/enable'}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default ProtectedRoute;
