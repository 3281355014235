import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  width: 600px;
  height: 792px;
  left: 277px;
  top: 1000.5px;

  background: #ffffff;
`;
export const Content = styled.div`
  margin-top: 100px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/images/earth.jpg');
  background-position: center;

  width: 600px;
  height: 194px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > h1 {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const RometLogo = styled.img`
  max-width: 100%;
  //  max-width: 200px;
`;

export const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 46px;
`;

export const ContainerContent = styled.div`
  padding: 0 40px;
  > h1 {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 34px;
    font-weight: 400;

    margin: 56px 0px 19px 0;
  }

  > div:nth-child(2) {
    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
    }
  }
`;

export const Footer = styled.div`
  padding: 24px 40px;
  width: 600px;
  height: 194px;

  background: linear-gradient(0deg, rgba(12, 35, 64, 0.1), rgba(12, 35, 64, 0.1)), #ffffff;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;

    > h1 {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: ${(props) => props.theme.colors.primary};
    }

    > span {
      font-weight: 400;
      font-size: 12px;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: space-between;

    width: 100%;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;

      gap: 8px;

      > img {
        width: 80px;
      }

      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      width: 168px;
      height: 60px;

      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
`;

export const Line = styled.div`
  border: 1px solid #000000;

  margin: 22px 0px 17px 0px;

  opacity: 0.3;
`;
