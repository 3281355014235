import styled, { css, keyframes } from 'styled-components';

type SpinnerProps = {
  variant?: 'primary';
};

const spinningAnimation = keyframes`

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

`;

const getBorderColor = (variant: SpinnerProps['variant']) => {
  switch (variant) {
    case 'primary':
      return css`
        ${(props) => props.theme.colors.primary}
      `;
    default:
      return 'rgba(255, 255, 255, 0.3)';
  }
};

export const Spin = styled.div<SpinnerProps>`
  background-color: transparent;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid ${(props) => getBorderColor(props.variant)};
  border-top: 5px solid ${(props) => props.theme.colors.white};
  animation: ${spinningAnimation} infinite 500ms linear;
`;
