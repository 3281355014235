import jwtDecode from 'jwt-decode';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthenticateUserQuery, AuthenticateUserQueryVariables } from '../API';
import { apiClient } from '../api-client';
import { authenticateUser } from '../graphql/queries';
import User from '../services/User';
import UserSettingsUtils from '../utils/UserSettingsUtils';
import { useTheme } from './Theme';

type SignInReturn = { success: boolean; message?: string; session?: string };

type AuthCtx = {
  refactoredUser?: User;
  signed: boolean;
  logo: string | null;
  user?: any;
  userApplications: string[];
  mfaEnabled: boolean | null;
  loading: boolean;
  setLogo: React.Dispatch<React.SetStateAction<string | null>> | null;
  setMfaEnabled: React.Dispatch<React.SetStateAction<boolean | null>> | null;
  signIn: (credentials: AuthenticateUserQueryVariables) => Promise<SignInReturn>;
  logout: () => void;
  isAdmin: () => boolean;
};

const AuthContext = createContext<AuthCtx | null>(null);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [logo, setLogo] = useState<string | null>(null);
  const [mfaEnabled, setMfaEnabled] = useState<boolean | null>(null);
  const [userApplications, setUserApplications] = useState<string[]>([]);
  const { setTheme } = useTheme() as any;

  const refactoredUser = new User(user);

  const logout = useCallback(async () => {
    localStorage.clear();
    localStorage.setItem('logout', 'true');
    UserSettingsUtils.clearSettings();
    setUser(null);
    setTheme('light');
    setLogo(null);
    setMfaEnabled(null);
  }, [setTheme]);

  const isAdmin = useCallback(() => {
    if (!user) return false;

    const allGroups = user['cognito:groups'] as any;

    const isAdmin = allGroups.some((group: any) => {
      const [, role] = group.split('/');

      return role === 'admin';
    });

    return isAdmin;
  }, [user]);

  const signIn = useCallback(
    async (credentials: any) => {
      const { data } = await apiClient.query<AuthenticateUserQueryVariables, AuthenticateUserQuery>(
        authenticateUser,
        { ...credentials }
      );

      const userConfigStr: string = data.authenticateUser;
      const userConfig = JSON.parse(userConfigStr);

      if (
        userConfig.message === 'TOTP required' ||
        userConfig.message === 'Invalid code received for user' ||
        userConfig.message === 'Invalid session for the user, session is expired.'
      ) {
        return {
          success: false,
          message: userConfig.message,
          session: userConfig.session,
        };
      }

      const accessToken = userConfig['access-token'];
      const theme = userConfig?.theme || 'light';
      const logo = userConfig.logo;
      const mfaEnabled = userConfig['mfa-enabled'];

      if (accessToken) {
        localStorage.setItem('userConfig', userConfigStr);
        setTheme(theme);
        setUser(jwtDecode(accessToken));
        setLogo(logo);
        setMfaEnabled(mfaEnabled);
        return {
          success: true,
        };
      } else {
        return {
          success: false,
        };
      }
    },
    [setTheme]
  );

  useEffect(() => {
    const userConfig = localStorage.getItem('userConfig');
    const parsedConfig = JSON.parse(userConfig!);

    const userToken = parsedConfig?.['access-token'] ?? null;
    const expiration = parsedConfig?.['expiration'] ?? null;
    const theme = parsedConfig?.['theme'] ?? 'light';
    const logo = parsedConfig?.['logo'] ?? null;
    const mfaEnabled = parsedConfig?.['mfa-enabled'] ?? null;
    const userApplications = parsedConfig?.['userApplications'] ?? [];

    const currentDate = Math.round(new Date().getTime() / 1000);

    if (!!expiration && expiration < currentDate) {
      logout();
      return;
    }

    if (userToken) {
      setTheme(theme);
      setLogo(logo);
      setMfaEnabled(mfaEnabled);
      setUserApplications(userApplications);
      setUser(jwtDecode(userToken));
    }
    setLoading(false);
  }, [logout, setTheme]);

  return (
    <AuthContext.Provider
      value={{
        user,
        logo,
        mfaEnabled,
        signed: !!user,
        refactoredUser,
        loading,
        setLogo,
        setMfaEnabled,
        signIn,
        logout,
        isAdmin,
        userApplications,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as AuthCtx;

export default AuthProvider;
