import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        scroll-margin: 10px;
    }
    
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 9999px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.lightGray};
        border-radius: 9999px;
        background-clip: padding-box;
    }
    
    ::-webkit-scrollbar-corner {
        border-radius: 9999px;
        
    }

    img {
        max-width: 100%;
    }

    a {
        text-decoration: none;
    }

    .btn {
        padding: 2px 10px;
        background-color: #ccc;
        color: black;
        border-radius: 5px;
    }

    input {
        color: ${(props) => props.theme.colors.black};
    }
`;
