import React from 'react';

import { IconPill } from './style';

export type PillItem = {
  color: string;
  icon: React.ReactNode;
};

export type PillProps = {
  containerStyle?: React.CSSProperties;
} & PillItem;

const Pill: React.FC<PillProps> = ({ color, icon, containerStyle }) => {
  return (
    <IconPill
      data-testid="pill"
      style={containerStyle}
      color={color}
    >
      {icon}
    </IconPill>
  );
};

export default Pill;
