import React, { PropsWithChildren } from 'react';

import { useAuth } from '../../contexts/Auth';
import { Role } from '../../types/UserRoles';

type CanProps = {
  role: Role | Role[];
};

const Can: React.FC<PropsWithChildren<CanProps>> = ({ role, children }) => {
  const { user, refactoredUser } = useAuth();

  if (!user) return null;

  if (refactoredUser?.hasRole(role)) return <>{children}</>;

  return null;
};

export default Can;
