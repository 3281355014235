const errorMessages = {
  required: 'This field is required',
  email: 'This field must be a valid email',
  minCharacters(min: number) {
    return `This field must be at least ${min} characters`;
  },
  maxCharacters(max: number) {
    return `This field must be at most ${max} characters`;
  },
  minNumber(min: number) {
    return `This field must be greater than or equal to ${min}`;
  },
  number: 'This field must be a valid number',
  moreThanField(field: string) {
    return `This field must be greater than ${field}`;
  },
  lessThanField(field: string) {
    return `This field must be less than ${field}`;
  },
};

export default errorMessages;
