import Button from '../../../components/Button/Button';
import FormControl from '../../../components/Forms/FormControl/FormControl';
import FormGroup from '../../../components/Forms/FormGroup/FormGroup';

import { ContainerTitle, FormBottom } from './style';

const CreatePassword = () => {
  return (
    <form>
      <ContainerTitle>
        <h1>Create Password</h1>

        <div>
          <span>Create a password for the user</span>
          <b> email@gmail.com</b>
        </div>
      </ContainerTitle>

      <FormGroup>
        <FormControl
          type="password"
          label="New Password"
          id="email"
          name="email"
        />
      </FormGroup>

      <FormGroup>
        <FormControl
          label="Confirm Password"
          id="password"
          name="password"
          type="password"
        />
      </FormGroup>

      <FormBottom>
        <Button>Create Password</Button>
      </FormBottom>
    </form>
  );
};

export default CreatePassword;
