import React, { createContext, useContext, useState, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';

import lightTheme from '../styles/theme';
import darkTheme from '../styles/dark';

type ThemeContextInterface = {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
};

const ThemeContext = createContext<ThemeContextInterface | null>(null);

const Theme: React.FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const themeValue = theme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <ThemeProvider theme={themeValue}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export default Theme;
